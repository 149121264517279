import {
  Flex,
  Heading,
  Box,
  Text,
  SimpleGrid,
  Spinner,
  Link,
  Grid,
  GridItem,
  Button,
} from "@chakra-ui/react";
import { AppProps } from "next/app";
import { useRouter } from "next/router";
import { useContext, useMemo, useState } from "react";
import { FaChevronRight } from "react-icons/fa";
import Achievement from "../../components/achievement";
import ChatTemplates from "../../components/chat-templates";
import DailySession from "../../components/chat-templates/daily-session";
import ChinaModal from "../../components/country-modals/china-modal";
import NextPrevButtons from "../../components/next-prev-buttons";
import PageContainer from "../../components/page-container";
import PageHeading from "../../components/page-heading";
import TemplateCard from "../../components/template-card";
import { siteConfig } from "../../configuration/config";
import { useConversationHistory } from "../../hooks/use-conversation-history";
import { LocalizationContext } from "../../hooks/use-localization";
import { useSettings } from "../../hooks/use-settings";
import { findAchievementForConversations } from "../../utils/conversation-achievements";
import { dateDistance, formatDate } from "../../utils/readable-date";
import { findAchievementForStreak } from "../../utils/streak-achievements";
import PwaPromptAndroid from "./dashboard/pwa-prompt-android";
import PwaPromptIOS from "./dashboard/pwa-prompt-ios";
import { interpolateString } from "../../utils/interpolate-string";
import { MdLogout } from "react-icons/md";

function App({ Component, pageProps }: AppProps) {
  const { l } = useContext(LocalizationContext);
  const { settings } = useSettings();
  const [conversationItemCount, setConversationItemCount] = useState(0);
  const { conversationHistory, conversationHistoryIsLoading } = useConversationHistory();
  const router = useRouter();

  const conversationPageLength = 6;

  const achievements = useMemo(() => {
    const current = findAchievementForStreak(conversationHistory?.streaks?.currentStreak);
    const best = findAchievementForStreak(conversationHistory?.streaks?.longestStreak);

    const conversations = findAchievementForConversations(
      conversationHistory?.conversations?.length,
    );

    return { current, best, conversations };
  }, [conversationHistory]);

  const conversations = useMemo(() => {
    return conversationHistory?.conversations?.slice(
      conversationItemCount,
      conversationItemCount + conversationPageLength,
    );
  }, [conversationHistory, conversationItemCount]);

  return (
    <Box h="100%">
      <PageContainer>
        <PageHeading
          subHeading={l["dashboard.subtitle"]}
          asidePosition={
            siteConfig.parentService && (
              <Button
                colorScheme="brand.primary"
                leftIcon={<MdLogout />}
                variant="link"
                size="xs"
                as="a"
                href={siteConfig.parentService.link}
              >
                {interpolateString(l["layout.parentServiceLink"], {
                  name: siteConfig.parentService.name,
                })}
              </Button>
            )
          }
        >
          {l["dashboard.title"]}
        </PageHeading>

        <PwaPromptIOS />
        <PwaPromptAndroid />
        <ChinaModal />

        <Grid templateRows="repeat(2, 1fr)" templateColumns="repeat(3, 1fr)" gap={5} w="full">
          <GridItem minH="150px" rowSpan={2} colSpan={{ base: 3, lg: 1 }}>
            <DailySession />
          </GridItem>
          <GridItem rowSpan={2} colSpan={{ base: 3, lg: 2 }}>
            <SimpleGrid columns={{ base: 1, sm: 2, xl: 2 }} spacing={5}>
              <ChatTemplates templateSelection="dashboard" />
            </SimpleGrid>
          </GridItem>
        </Grid>

        {siteConfig.showLanguageGuides &&
          (settings.practiceLanguageProficiency === "beginner" ||
            settings.practiceLanguageProficiency === "elementary") && (
            <>
              <Heading mt={7} size={"xs"} as="h3" mb={4} textAlign="center">
                {l["guide.title"]}
              </Heading>

              <SimpleGrid columns={{ base: 1, xl: 3 }} spacing={5}>
                <TemplateCard
                  buttonBackgroundColor="brand.gray.200"
                  image="/guides/beginner-intro.jpeg"
                  buttonText={"guide.beginnerIntro.title"}
                  description={"guide.beginnerIntro.shortText"}
                  onClick={() => router.push("/app/guide/beginner-intro")}
                />

                <TemplateCard
                  buttonBackgroundColor="brand.gray.200"
                  image="/guides/beginner-counting.jpeg"
                  buttonText={"guide.beginnerCounting.title"}
                  description={"guide.beginnerCounting.shortText"}
                  onClick={() => router.push("/app/guide/beginner-counting")}
                />

                <TemplateCard
                  buttonBackgroundColor="brand.gray.200"
                  image="/guides/beginner-common-verbs.jpeg"
                  buttonText={"guide.beginnerCommonVerbs.title"}
                  description={"guide.beginnerCommonVerbs.shortText"}
                  onClick={() => router.push("/app/guide/beginner-common-verbs")}
                />
              </SimpleGrid>
            </>
          )}

        {siteConfig.hideProgressMenuLink &&
          (!siteConfig.hideDashboardStreaks || !siteConfig.hideDashboardConversationHistory) && (
            <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={5}>
              {siteConfig.hideDashboardStreaks ? null : (
                <Box
                  background="gray.600"
                  color="#fff"
                  py={5}
                  px={10}
                  mt={5}
                  pb={10}
                  borderRadius={7}
                >
                  <Heading mt={3} size={"xs"} as="h3" mb={3} textAlign="center">
                    {l["dashboard.learningStreaks.title"]}
                  </Heading>

                  <Text textAlign="center" mb={7}>
                    {l["dashboard.learningStreaks.description"]}
                  </Text>

                  {conversationHistoryIsLoading ? (
                    <Spinner />
                  ) : (
                    <>
                      {conversationHistory && conversationHistory.conversations?.length ? (
                        <>
                          <Flex flexDir={"column"}>
                            <Achievement
                              backgroundColor="brand.blue.100"
                              color="black"
                              count={conversationHistory.streaks.currentStreak}
                              label={l["dashboard.learningStreaks.dayStreak"]}
                              statementKey={"achievement"}
                              statementScore={achievements.current[1]}
                              statementEmoticon={achievements.current[0]}
                            />

                            <Box my={2}></Box>

                            <Achievement
                              backgroundColor="brand.green.100"
                              color="black"
                              count={conversationHistory.streaks.longestStreak}
                              label={l["dashboard.learningStreaks.longestStreak"]}
                              statementKey={"achievement"}
                              statementScore={achievements.best[1]}
                              statementEmoticon={achievements.best[0]}
                            />

                            <Box my={2}></Box>

                            <Achievement
                              backgroundColor="brand.purple.100"
                              color="black"
                              count={conversationHistory.conversations?.length}
                              label={l["dashboard.learningStreaks.conversations"]}
                              statementKey={"conversation"}
                              statementScore={achievements.conversations[1]}
                              statementEmoticon={achievements.conversations[0]}
                            />
                          </Flex>
                        </>
                      ) : (
                        <Text textAlign={"center"} fontSize="xs" my={7}>
                          {l["app.noConversations"]}
                        </Text>
                      )}
                    </>
                  )}
                </Box>
              )}

              {siteConfig.hideDashboardConversationHistory ? null : (
                <Flex
                  flexDir={"column"}
                  background="gray.300"
                  mt={5}
                  py={5}
                  px={10}
                  borderRadius={7}
                >
                  <Heading mt={3} size={"xs"} as="h3" mb={3} textAlign="center">
                    {l["app.conversations"]}
                  </Heading>

                  <Text textAlign="center" mb={7}>
                    {l["dashboard.conversations.description"]}
                  </Text>

                  {conversationHistoryIsLoading ? (
                    <Spinner />
                  ) : (
                    <>
                      {conversations?.length ? (
                        <Flex flex={2} flexDir={"column"} justifyContent="space-between">
                          <Flex flexDir={"column"} minH="350px">
                            {conversations.map((c, i) => (
                              <Link
                                href={`/app/chat?id=${c.id}`}
                                display={"flex"}
                                key={i}
                                borderTop="1px solid #eee"
                                pt={3}
                                mt={3}
                                justifyContent="space-between"
                                alignItems={"center"}
                                _hover={{
                                  textDecoration: "none",
                                  opacity: 0.6,
                                }}
                              >
                                <Flex flexDir="column">
                                  <Text
                                    fontSize={{
                                      base: "xs",
                                      sm: "sm",
                                      xl: "md",
                                    }}
                                  >
                                    {formatDate(new Date(c.createdAt), settings.uiLanguage)}
                                  </Text>
                                  <Text
                                    noOfLines={1}
                                    textTransform={"capitalize"}
                                    mr={2}
                                    fontWeight="bold"
                                    fontSize={{
                                      base: "2xs",
                                      sm: "xs",
                                      xl: "sm",
                                    }}
                                    color="brand.gray.600"
                                  >
                                    {dateDistance(new Date(c.createdAt), settings.uiLanguage)}
                                  </Text>
                                </Flex>
                                <Box color="brand.gray.600">
                                  <FaChevronRight />
                                </Box>
                              </Link>
                            ))}
                          </Flex>

                          <Box mt={7}>
                            <NextPrevButtons
                              onNextClick={() =>
                                setConversationItemCount(
                                  conversationItemCount + conversationPageLength,
                                )
                              }
                              onPrevClick={() =>
                                setConversationItemCount(
                                  conversationItemCount - conversationPageLength,
                                )
                              }
                              showPrev={conversationItemCount !== 0}
                              showNext={
                                conversationItemCount + conversationPageLength <
                                (conversationHistory?.conversations?.length || 0)
                              }
                            />
                          </Box>
                        </Flex>
                      ) : (
                        <Text textAlign={"center"} fontSize="xs" mb={7}>
                          {l["app.noConversations"]}
                        </Text>
                      )}
                    </>
                  )}
                </Flex>
              )}
            </SimpleGrid>
          )}
      </PageContainer>
    </Box>
  );
}

export default App;
